<script setup>
import { getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { internalApiRequest } from '@/shared/services/apiService'
import { useMicrosoftSignInPopup } from '@/features/access/services/authenticationService'
import microsoftLogoSrc from '@/app/assets/logos/logo_microsoft.svg'

const app = getCurrentInstance()
const router = useRouter()
const submitted = ref(false)

defineProps({
  label: {
    type: String,
    default: 'Microsoft',
  },
})

function onMicrosoftSignIn() {
  submitted.value = true

  useMicrosoftSignInPopup()
    .then((resp) => {
      internalApiRequest({
        method: 'POST',
        path: `api/v1/azure-login`,
        data: {
          tokenId: resp?.accessToken,
          email: resp?.user?.email,
          firstname: resp?.user?.displayName?.split(' ').slice(0, -1)?.join(' '),
          lastname: resp?.user?.displayName?.split(' ').slice(-1)?.join(' '),
        },
      })
        .then((resp) => {
          localStorage.setItem('token', resp?.access_token)

          router.push({
            name: 'dashboard',
          })
        })
        .catch(() => {
          app.proxy.$notification.error(app.proxy.$t('verification.failed'))
        })
    })
    .finally(() => {
      submitted.value = false
    })
}
</script>

<template>
  <v-btn color="primary" :loading="submitted" block outlined @click="onMicrosoftSignIn">
    <v-avatar size="25" class="mr-2">
      <v-img :src="microsoftLogoSrc" alt="" max-height="20px" width="auto" contain></v-img>
    </v-avatar>
    {{ label }}
  </v-btn>
</template>

<style scoped lang="scss"></style>
