import { render, staticRenderFns } from "./MicrosoftSignInButton.vue?vue&type=template&id=0bbfaaaf&scoped=true"
import script from "./MicrosoftSignInButton.vue?vue&type=script&setup=true&lang=js"
export * from "./MicrosoftSignInButton.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bbfaaaf",
  null
  
)

export default component.exports